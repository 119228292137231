import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
// import getJwtToken from '../services/getToken';
import refreshJwt from '../services/refreshToken';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: LayoutProps) => {
    React.useEffect(() => {        
        const interval = setInterval(() => {
          refreshJwt();
        }, 1200000);
      
        return () => {         
          clearInterval(interval);
        };
      }, []);
    return <Layout {...props} appBar={AppBar} menu={Menu} />;
};
