import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

const errorControl = onError((errResp) => {
    // { graphQLErrors, networkError, response }
    console.log("errResp", errResp);

});
const anonymous = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL_ANONYMOUS,
});

let shielded = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL_SHIELDED,
    headers: {
        // ...headers,
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`
    }
    
}   );
let link = ApolloLink.from([
    errorControl,
    ApolloLink.split(
        (operation) => operation.getContext().anonymous === true,
        anonymous,
        shielded
    ), 
]);
export let client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    queryDeduplication: false,
});

export function updateToken(){    
    shielded = new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URL_SHIELDED,
        headers: {
            // ...headers,
            Accept: "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`
        }
        
    }   );
    link = ApolloLink.from([
        errorControl,
        ApolloLink.split(
            (operation) => operation.getContext().anonymous === true,
            anonymous,
            shielded
        ), 
    ]);
    client = new ApolloClient({
        link: link,
        cache: new InMemoryCache(),
        queryDeduplication: false,
    });    
}