// deno-lint-ignore-file

import { fetchUtils } from 'react-admin';
import { updateToken } from '../dataProvider/Graphql.module';
// import { tokenService } from '../dataProvider/token.service';
// import { updateToken } from '../dataProvider/Graphql.module';
const postgraphileUrl = process.env.REACT_APP_GRAPHQL_URL_ANONYMOUS;
const httpClient = fetchUtils.fetchJson;
const refreshJwt = async () => {
    const mutation = `mutation{
  refreshAdminToken(input: {}) {
    string
  }
}
`;
   
    const query = JSON.stringify({
    query: mutation
    })
    const postQuery = {
    method: 'POST',
    body: query,
  };
    // if (!JSON.parse(localStorage.getItem('jwtToken'))?.expiry || JSON.parse(localStorage.getItem('jwtToken'))?.expiry<=new Date().getTime()) {
        await httpClient(postgraphileUrl, postQuery).then(
        ({ headers, json }) => {
                // console.log('---------------------');
                // console.log(json.data.refreshAdminToken.string);
                // console.log('---------------------');        
        const item = {
            value: json.data.refreshAdminToken.string,
          expiry: new Date().getTime() + 900000 //in milliseconds
            
        }
                localStorage.setItem('jwtToken', JSON.stringify(item));
                updateToken();
                return true;
        });
    // }      
}
export default refreshJwt;