import React, { FC,useEffect,useState } from 'react';

import { Box } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useRecordContext } from 'react-admin';

interface Props {
  label: string;
  field: string;
}

const CustomViewField: FC<Props> = (props) => {
  const record = useRecordContext();   
  const [permission, setPermission] = useState({
    enableEdit: false,
  });
  const validatePermissions = (status: string, createdBy: number) => {
    const userRole = JSON.parse(localStorage.getItem('auth'))?.userTypeId;
    const userId = JSON.parse(localStorage.getItem('auth'))?.userId;
    let enableEdit = false;
 
    switch (userRole) {
        case process.env.REACT_APP_MICROSITE_ADMIN:                             
            enableEdit = true;      
            break;
        case process.env.REACT_APP_CURATOR:
            enableEdit = true; 
        // if (record.publish === true) {
        //       enableEdit = false;
        //     }
            break;
        case process.env.REACT_APP_CONTENT_ADMINISTRATOR:
            enableEdit = true; 
            break;
        default:
        if (status === process.env.REACT_APP_CURATION_DEFAULT_STATUS) {
                if (userId === createdBy) {
                   enableEdit = true; 
                }
            }
    }
    setPermission(permission => ({
        ...permission,
        enableEdit: enableEdit      
    }));
   

  }
   useEffect(() => {        
      validatePermissions(record.status, record.createdBy);
    },[]);  
 
  if (props.field === 'view') {
     let urlPath = '#/viewCuration/' + record.id;
    return (
      <Box component='div'>
        <a href={urlPath} rel='noopener noreferrer'>
          <VisibilityOutlinedIcon />
        </a>
      </Box>
    )
  } else if (props.field === 'edit') {
    

    let urlPath = '#/updateCuration/' + record.id;
    return (
     (permission.enableEdit)?(<Box component='div'>
        <a href={urlPath} rel='noopener noreferrer'>
          <EditOutlinedIcon />
        </a>
      </Box>):null
    )    
  }

};


export default CustomViewField;
